import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  MANAGE_SUBS_RESET_CONDITION_DATA,
} from './constants';
import { BOS_SEARCH_CONDITION_KEY } from '../../../common/constants';

export function resetConditionData() {
  return {
    type: MANAGE_SUBS_RESET_CONDITION_DATA,
  };
}

export function useResetConditionData() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(resetConditionData(...params)), [dispatch]);
  return { resetConditionData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_RESET_CONDITION_DATA:
      sessionStorage.removeItem(BOS_SEARCH_CONDITION_KEY);
      return {
        ...state,
        conditionData: null,
      };

    default:
      return state;
  }
}
