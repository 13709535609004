// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { PageNotFound } from '../common';
import { ReportsLayoutContent } from '../reports-new';
import { OosCategory, OosCustomer, OosItem } from './';

export default {
  path: 'out-of-stock',
  component: ReportsLayoutContent,
  childRoutes: [
    {
      path: '/',
      component: OosCategory,
      isIndex: true,
    },
    {
      path: 'customer',
      component: OosCustomer,
    },
    {
      path: 'order',
      component: OosItem,
    },
    { path: '*', component: PageNotFound },//COT-9353: SIA+ Screen refresh issue. handle incorrect path
  ],
};
