// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { ManageSubs, MaintainAccounts, ManageOuts, ManageSubstitutions, AdminConsole, CustomerGroup, ManageAccountsAuditTrail, SubRepositoriesAuditTrail, CreateGroupsAuditTrail } from './';
import { PageNotFound } from '../common';

export default {
  path: 'manage-subs',
  component: ManageSubs,
  childRoutes: [
    {
      path: 'maintain-accounts',
      component: MaintainAccounts,
    }, {
      path: 'manage-outs',
      component: ManageOuts,
      isIndex: true,
    }, {
      path: 'sub-repositories',
      component: ManageSubstitutions,
    }, {
      path: 'admin-console',
      component: AdminConsole,
    }, {
      path: 'manage-groups',
      component: CustomerGroup,
    }, 
    {
      path: 'manage-accounts-audit-trail',
      component: ManageAccountsAuditTrail,
    },
    {
      path: 'sub-repositories-audit-trail',
      component: SubRepositoriesAuditTrail,
    }, 
    {
      path: 'create-groups-audit-trail',
      component: CreateGroupsAuditTrail,
    },
      { path: '*', component: PageNotFound },//COT-9353: SIA+ Screen refresh issue. handle incorrect path
  ],
};
