import { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  REPORTS_NEW_SAVE_REPORTS_KEY_VALUE,
} from './constants';
import { SIA_REPORTS_KEY_VALUE } from '../../../common/constants';

export function saveReportsKeyValue(reportsKeyValue) {
  return {
    type: REPORTS_NEW_SAVE_REPORTS_KEY_VALUE,
    data: reportsKeyValue
  };
}

export function useSaveReportsKeyValue() {
  const dispatch = useDispatch();
  const reportsKeyValue = useSelector(state => state.reportsNew.reportsKeyValue);
  const boundAction = useCallback((...params) => dispatch(saveReportsKeyValue(...params)), [dispatch]);
  return { reportsKeyValue,saveReportsKeyValue: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case REPORTS_NEW_SAVE_REPORTS_KEY_VALUE:
      sessionStorage.setItem(SIA_REPORTS_KEY_VALUE,JSON.stringify(action.data));
      return {
        ...state,
        reportsKeyValue: action.data
      };

    default:
      return state;
  }
}
