import React from 'react';
import { useSelector } from 'react-redux';
import { CONST_REPORT_TABS_NEW_COLUMNS } from '../../common/reportsConstants'
import CommonPage from './CommonPage';
import DailySalesRegion from './DailySalesRegion';
import DailySalesAccountType from './DailySalesAccountType';
import { SIA_TERRITORY_DATA_KEY } from '../../common/constants';
export default function DailySalesSummary() {
  const {isSiteUser,} = useSelector(state => ({
    isSiteUser:state.dashboard.isSiteUser,
  }));
  //COT-9353: SIA+ Screen refresh issue. if refreshs screen,will get isSiteUser from session storage
  const sessionIsSiteUser = JSON.parse(sessionStorage.getItem(SIA_TERRITORY_DATA_KEY))?.isSiteUser;
  return (
    <div className="reports-new-daily-sales-summary">
      {/* {!isSiteUser ?<DailySalesRegion/>:<DailySalesAccountType/>} */}
      {isSiteUser || sessionIsSiteUser ? <DailySalesAccountType/>: <DailySalesRegion/>}
    </div>
  );
};

DailySalesSummary.propTypes = {};
DailySalesSummary.defaultProps = {};
