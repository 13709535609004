import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col, Form, Select, DatePicker, Button, Popover, Input, Card, Table, message, Collapse, Tooltip } from 'antd';
import { ERROR_SELECT_TERRITORY, DASH_DATE_FORMAT, SELECT_ALL_OPTION, ACCOUNT_TYPES, SIA_ORG_DROPDOWN_LIST_KEY, SIA_TERRITORY_DATA_KEY, SIA_DASHBOARD_CONDITION_DATA_KEY, SIA_TEST_USER_ID_KEY } from '../../common/constants';
import { getOrgSelectedList, getTerritoryList, uniqueTerritoryList } from '../dashboard/getOrgSelectedList';
import { formatTerrirotyData } from '../dashboard/formatTerrirotyData';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import {
  useFetchOrgInfo,
  useFetchTerritoryData,
  useFetchSummaryData,
  useFetchNationalAccountData,
  useFetchConceptIdsData,
  useResetConceptIdsData,
  useResetSummaryData,
  useSaveDashboardConditionData,
  useResetDashboardConditionData,
  useSaveInitTerritoryFiltersData,
  useSaveDropDownList,
} from '../dashboard/redux/hooks';
import {useResetReportsData} from '../reports/redux/hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
import i18n from '../../i18n/i18n';
import { RightOutlined } from '@ant-design/icons';
export default function ReportsFilter(props) {
  const { t } = useTranslation();
  const {isOpenPopFun,isOpenPop,isClosePopFun,} = props
  const { fetchOrgInfo, orgList, orgConstData } = useFetchOrgInfo();
  const { territoryData, fetchTerritoryData } = useFetchTerritoryData();
  const { fetchSummaryData } = useFetchSummaryData();
  const { fetchNationalAccountData, nationalData } = useFetchNationalAccountData();
  const { fetchConceptIdsData, conceptData } = useFetchConceptIdsData();
  const { resetConceptIdsData } = useResetConceptIdsData();
  const { resetSummaryData } = useResetSummaryData();
  const { resetReportsData } = useResetReportsData();
  const { saveDashboardConditionData } = useSaveDashboardConditionData();
  const { resetDashboardConditionData } = useResetDashboardConditionData();
  const { initTerritoryFiltersData, saveInitTerritoryFiltersData } = useSaveInitTerritoryFiltersData();
  const [orgSelectedList, setOrgSelectedList] = useState(orgConstData);
  const [orgDropdownList, setOrgDropdownList] = useState(orgConstData);
	const [conceptIdData, setConceptIdData] = useState();
  const [territoryList, setTerritoryList] = useState([]);
  const [form] = Form.useForm();
  const { auth, dashboardConditionData,isCanadaCorpUser,dropDownList } = useSelector(state => ({
    auth: state.home.auth,
    dashboardConditionData: state.dashboard.dashboardConditionData,
    isCanadaCorpUser: state.dashboard.isCanadaCorpUser,
    dropDownList: state.dashboard.dropDownList,
  }));
  //Test Code Only for dev and qa
  // const [visible, setVisible] = useState(false);
  // const [testUserId, setTestUserId] = useState(auth.user.userId);
  // const hide = () => {
  //   setVisible(false);
  // };
  // const handleVisibleChange = (newOpen) => {
    // setVisible(newOpen);
  // };
  // const onOk = () => {
  //   hide();
  //   let userId = testUserId ? testUserId : auth.user.userId
  //   //nbur3044 region; //abry1817 ma //rkea4241 site //ecui8760 corp
  //   getTerritoryData(userId)
  // };
  // const onTestUserIdChg = (e) => {
  //   setTestUserId(e.target.value);
  // };
  // const handlePressEnter = (e) => {
  //   e.target.blur();
  //   onOk();
  // };
  // const content = (
  //   <div>
  //     <Input allowClear value={testUserId} onChange={onTestUserIdChg} onPressEnter={handlePressEnter} />
  //     <div className="btn-footer flex justify-end" style={{ marginTop: "10px" }}>
  //       {/* <Button onClick={onClear} className="default-btn">
  //         Clear
  //       </Button> */}
  //       <Button onClick={onOk} type="primary" className='primary-btn' style={{ marginLeft: "4px" }}>
  //         Ok
  //       </Button>
  //     </div>
  //   </div>
  // );
  const getTerritoryData = (userId) => {
    fetchTerritoryData(userId).then(territoryData => {
      if(territoryData.role !== 'Corp' && territoryData.market === null){ //if incorrect users (1,tied to a territory and this territory is assigned to the sites which are not supported by SIA+;2, not tied to sites but role is site) , will disable options and show message
        message.error(`No data to display for user - ${auth.user.userId}`,10);
        setOrgDropdownList({"market":[],"region":[],"site":[]});
        setTerritoryList([]);
        const initOrgSelectedList ={"market":[],"region":[],"site":[],"territory":[]};
        setOrgSelectedList(initOrgSelectedList);
        saveInitTerritoryFiltersData(initOrgSelectedList);
        form.setFieldsValue(initOrgSelectedList);
      }else{

        const role = territoryData.role;
        const sellerId = territoryData.sellerId;
        const defaultMarket = territoryData.market ? territoryData.market.split() : null;
        const defaultRegion =  territoryData.regions ? territoryData.regions : null;
        const defaultSiteObj = territoryData.sites;
        const defaultSite = territoryData.sites ? territoryData.sites.map(item => item.site_nbr) : null;
        const defaultOrgSelectedList = {
          market: defaultMarket,
          region: defaultRegion,
          site: defaultSite,
        };

        fetchOrgInfo(defaultMarket, defaultRegion, defaultSiteObj,role,sellerId).then(orgData => {
          const initOrgList = orgData.orgList;
          const defaultOrgDropdownList = orgData.orgConstData;
          let initOrgSelectedList = JSON.parse(JSON.stringify(defaultOrgDropdownList));
          initOrgSelectedList.site = initOrgSelectedList.site.map(item => item.site_nbr);
          setOrgDropdownList(defaultOrgDropdownList);
          let defaultFormValues;
          if (role === 'Market') {
            defaultFormValues = defaultMarket;
          } else if (role === 'Region') {
            defaultFormValues = defaultRegion;
          } else if (role === 'Site' || role === 'MA') {
            //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
            defaultFormValues = defaultSite.filter((item1) => defaultOrgDropdownList.site.some((item2) => item2.site_nbr === item1));
          } else {
            defaultFormValues = [];
          }//COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
          const territories = uniqueTerritoryList(getTerritoryList(initOrgSelectedList.site,territoryData.territories));
          setTerritoryList(territories || []);
          const newOrgList = getOrgSelectedList(
            defaultFormValues,
            role,
            defaultOrgSelectedList,
            initOrgList,
            defaultOrgDropdownList,
            setOrgDropdownList,
            territories,
            setTerritoryList,
            territories,
            conceptIdData,
          );
          initOrgSelectedList = newOrgList.newOrgSelectedList;
          saveInitTerritoryFiltersData(initOrgSelectedList);
		      //COT-9353: SIA+ Screen refresh issue,if refresh screen, will set values from storage
          let storagedCondition = JSON.parse(sessionStorage.getItem(SIA_DASHBOARD_CONDITION_DATA_KEY));
          if(!_.isEmpty(storagedCondition)){
            initOrgSelectedList = {...storagedCondition,deliveryDate: moment(storagedCondition?.deliveryDate)};
          }
          let storagedOrgDropdownList = JSON.parse(sessionStorage.getItem(SIA_ORG_DROPDOWN_LIST_KEY));
          if(!_.isEmpty(storagedOrgDropdownList)){
            setOrgDropdownList(storagedOrgDropdownList)
          }
          if(storagedOrgDropdownList?.conceptId){
            setConceptIdData(storagedOrgDropdownList?.conceptId);
          }
          setOrgSelectedList(initOrgSelectedList);
          //initial form default Values: Region Role, Site Role, MA Role
          form.setFieldsValue(initOrgSelectedList);
        });
      }
      fetchNationalAccountData();
    });
  }
//COT-9353: SIA+ Screen refresh issue. If switch to test user,then refresh will get userId from session storage
  let sessionUserId = sessionStorage.getItem(SIA_TEST_USER_ID_KEY);
  useEffect(() => {
    if (orgList.length === 0) {
       let userId = auth.user.userId;
      if(!_.isEmpty(sessionUserId)){
        if(sessionUserId !== userId){
          userId = sessionUserId; 
        }
      }
      getTerritoryData(userId)
    }
  }, []); // eslint-disable-line
  //COT-9353: SIA+ Screen refresh issue.need store conceptId drop down list,so need re-render state.
  useEffect(()=>{
    setConceptIdData(conceptData);
  },[conceptData]);
  //if switch to dashboard menu from other sia plus menus will execute below code , if refresh dashboard screen will execute below firstly
  useEffect(() => {
    let storagedCondition = JSON.parse(sessionStorage.getItem(SIA_DASHBOARD_CONDITION_DATA_KEY));
    if(dashboardConditionData || !_.isEmpty(storagedCondition)) {
      form.setFieldsValue(dashboardConditionData ? dashboardConditionData : {...storagedCondition,deliveryDate: moment(storagedCondition?.deliveryDate)});
      setOrgSelectedList(dashboardConditionData ? dashboardConditionData : {...storagedCondition,deliveryDate: moment(storagedCondition?.deliveryDate)});
      let storagedorgDropdownList = JSON.parse(sessionStorage.getItem(SIA_ORG_DROPDOWN_LIST_KEY));
      if(!_.isEmpty(storagedorgDropdownList)){
        setOrgDropdownList(storagedorgDropdownList);
        if(storagedorgDropdownList?.conceptId){
          setConceptIdData(storagedorgDropdownList?.conceptId);
        }
        //COT-9353: SIA+ Screen refresh issue. if roll back to dashboard screen, territoryList's length is 0, will disable territory textbox for MA user
        if(!_.isEmpty(storagedorgDropdownList?.territoryList)){
          setTerritoryList(storagedorgDropdownList?.territoryList);
        }
      }
    } else {
      form.setFieldsValue(initTerritoryFiltersData);
      setOrgSelectedList(initTerritoryFiltersData || orgSelectedList);
      //COT-8700:fixed wrong data issue, like userid bind with wrong sites(139,162) that not belong to the  returned region
      const territories = territoryData ? uniqueTerritoryList(getTerritoryList(orgSelectedList.site,territoryData.territories)) : null;
      setTerritoryList(territories || []);
    }
  }, [dashboardConditionData]); // eslint-disable-line
const { saveDropDownList } = useSaveDropDownList();
  const onFinish = values => {
    const submitData = {
      deliveryDate: moment(values.deliveryDate).format(DASH_DATE_FORMAT),
      sites: values.site,
      territoryInfoList: formatTerrirotyData(values, territoryData ? territoryData : JSON.parse(sessionStorage.getItem(SIA_TERRITORY_DATA_KEY))?.territoryData), //COT-9353: SIA+ Screen refresh issue. if refresh screen, territoryData will null and throws error in this method
      conceptIds: values.conceptId !== undefined ? values.conceptId : [],
      nationalIds: values.nationalId !== undefined ? values.nationalId.split() : [],
      accountTypes: values.accountType !== undefined ? values.accountType : [],
    };
    const timing = Date.now()
    fetchSummaryData(submitData);
    datadogRum.onReady(function(){
      datadogRum.addTiming(`Fetch dashboard data`,timing);
    });
    saveDashboardConditionData(values);
    //COT-9353: SIA+ Screen refresh issue,if selected nationalId filter, will store conceptId dropdown list
    //if switch menu will save drop down list for market , region and site fields
    saveDropDownList({...orgDropdownList,territoryList: territoryList,conceptId: conceptIdData});
  };
  const handleSelectChange = (value, label) => {
    const newList = getOrgSelectedList(
      value,
      label,
      orgSelectedList,
      orgList,
      orgDropdownList,
      setOrgDropdownList,
      territoryData.territories,
      setTerritoryList,
      territoryList,
      conceptIdData,
      territoryData
    );
    if(label === 'accountType' && _.isEmpty(value)){
      newList.newOrgSelectedList.accountType = ['CMU', 'LCC','TRS','TRP','OTH'];
    }
    setOrgSelectedList(newList.newOrgSelectedList);
    form.setFieldsValue(newList.newOrgSelectedList);
  };

  const getConceptIdsList = value => {
    //except onClear
    form.setFieldsValue({ conceptId: undefined });
    if (value !== undefined) {
      fetchConceptIdsData(value);
    }
  };

  const disabledDeliveryDate = currentDate => {
    return currentDate && currentDate < moment().subtract(3, 'days');
  };
  const onReset = () => {
    form.resetFields();
    // resetSummaryData();
    resetReportsData();
    resetDashboardConditionData();
  };
  const { Panel } = Collapse;
  var viewportWidth = document.documentElement.clientWidth;
  let offset = 4;
  if(viewportWidth >= 1495){
    offset = 5;
  }else if(viewportWidth <= 1230){
    offset = 3; 
  }else if(viewportWidth <= 1055){
    offset = 2;
  }
  return (
    <Card className="reports-new-reports-filter">
      <Form
        form={form}
        name="reports-filter-form"
        className="reports-filter-form"
        // layout="vertical"
        onFinish={onFinish}
        initialValues={{
          deliveryDate: moment().add(1, 'days'),
          accountType:['CMU', 'LCC', 'TRS', 'TRP', 'OTH'],
        }}
      >
        <div className="sia-filter-pc-row">
      
          <div className="sub-title font-size-16 is-show-mobile">
              {t('filters')}
          </div>
          <FontAwesomeIcon icon={faSliders} className="filter-but-right-mobile is-show-mobile" onClick={isOpenPopFun}/>
        
          <div className="sia-filter-pc">
            <Collapse expandIconPosition="right" expandIcon={({ isActive }) =><Tooltip title="Fitlers"><RightOutlined rotate={isActive ? 90 : 0} /></Tooltip>}>
              <Panel header={t('filters')} >
                <Row gutter={8}>
                  <Col offset={offset} style={{ paddingLeft: 0, paddingRight: 0 }}><label className="sub-title-show font-size-14-bold">{t('show')}:</label></Col>
                  <Col span={3}>
                    <Form.Item name="market">
                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        onChange={value => handleSelectChange(value, 'market')}
                        placeholder={t('market')}
                        disabled={
                          territoryData && territoryData.role && territoryData.role.toLowerCase() !== 'corp'
                        }
                      // style={{width :120}}
                      >
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          {t('allMarkets')}
                        </Select.Option>
                        {orgDropdownList.market.map(item => {
                          return (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name="region">
                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={9}
                        onChange={value => handleSelectChange(value, 'region')}
                        placeholder={t('region')}
                        disabled={
                          orgSelectedList.market.length === 0 ||
                          (territoryData &&
                            territoryData.role &&
                            territoryData.role.toLowerCase() !== 'corp' &&
                            territoryData.role.toLowerCase() !== 'market' &&
                            territoryData?.role.toLowerCase() !== 'ma')
                        }
                      // style={{width :120}}
                      >
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          {t('allRegions')}
                        </Select.Option>
                        {orgDropdownList.region.map(item => {
                          return (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name="accountType"
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={9}
                        onChange={value => handleSelectChange(value, 'accountType')}
                        placeholder={t('dashboard_accountType')}
                      // style={{width :120}}
                      >
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          {t('all_account_types')}
                        </Select.Option>
                        {ACCOUNT_TYPES.map(item => {
                          return (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name="nationalId">
                      <Select
                        showSearch
                        allowClear
                        onChange={value => getConceptIdsList(value)}
                        onClear={() => {
                          resetConceptIdsData();
                        }}
                        placeholder={t('natlId')}
                        // style={{width :120}}
                        disabled={isCanadaCorpUser}
                      >
                        {nationalData &&
                          nationalData.map(item => {
                            return (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div className="error-delivery-date">
                      <Form.Item
                        name="deliveryDate"
                        // label="Delivery Date"
                        rules={[{ required: true, message: 'Please select Delivery Date!' }]}
                      >
                        <DatePicker
                          disabledDate={disabledDeliveryDate}
                          placeholder="Delivery Date"
                        // style={{width :130}}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Form.Item style={{ textAlign: 'end' }}>
                      <Button htmlType="submit" type="link" className="primary-btn">
                        {t('search')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} style={{ marginTop: 8 }}>
                  <Col offset={offset} style={{ paddingLeft: 0, paddingRight: 0, width: i18n.language === 'fr'? 74: 59 }}></Col>
                  <Col span={3}>
                    <Form.Item
                      name="site"
                      rules={[{ required: true, message: t('pleaseSelectSite') }]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={9}
                        onChange={value => handleSelectChange(value, 'site')}
                        placeholder={t('site')}
                        disabled={
                          orgSelectedList.region.length === 0
                          // || (territoryData && territoryData.role && territoryData.role.toLowerCase() === 'site')
                        }
                      >
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          {t("allSites")}
                        </Select.Option>
                        {orgDropdownList.site.map(item => {
                          const siteNbr = item.site_nbr;
                          const siteNbrName = item.site_desc;
                          return (
                            <Select.Option key={siteNbr} value={siteNbr}>
                              {`${siteNbr} - ${siteNbrName}`}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}></Col>
                  <Col span={3}>
                    <Form.Item
                      name="territory"
                      // label="Territory"
                      rules={[
                        {
                          required: territoryData && territoryData.role.toLowerCase() === 'ma',
                          message: ERROR_SELECT_TERRITORY,
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(value, option) => handleSelectChange(value, 'territory')}
                        placeholder={t("territory")}
                        disabled={orgSelectedList.site.length === 0 || territoryList.length === 0}
                      >
                        {territoryList ? (
                          <>
                            <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                              {t('allTerritories')}
                            </Select.Option>
                            {territoryList.map(item => {
                              return (
                                <Select.Option key={item.terrId} value={item.terrName}>
                                  {item.terrName}
                                </Select.Option>
                              );
                            })}
                          </>
                        ) : null}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item name="conceptId">
                      <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={6}
                        onChange={(value, option) => handleSelectChange(value, 'conceptId')}
                        placeholder={t('conceptId')}
                        disabled={isCanadaCorpUser}
                      >
                        {conceptIdData && (
                          <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                            {t('allConceptIds')}
                          </Select.Option>
                        )}
                        {conceptIdData &&
                          conceptIdData.map(item => {
                            return (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
          
          <div className={isOpenPop ? "sia-filter-mobile":"sia-filter-mobile none"}>
            <div className="sia-filter-top">
              <h4>{t("filters")}</h4>
              <FontAwesomeIcon icon={faSliders} className="filter-but-right-mobile is-show-mobile" onClick={isClosePopFun}/>
            </div>
            <div className="sia-filter-content">
              <Col flex="1 1 0">
                <Form.Item name="market">
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    onChange={value => handleSelectChange(value, 'market')}
                    placeholder={t("market")}
                    disabled={
                      territoryData && territoryData.role && territoryData.role.toLowerCase() !== 'corp'
                    }
                  >
                    <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                      {t("allMarkets")}
                    </Select.Option>
                    {orgDropdownList.market.map(item => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item name="region">
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    maxTagTextLength={9}
                    onChange={value => handleSelectChange(value, 'region')}
                    placeholder={t('region')}
                    disabled={
                      orgSelectedList.market.length === 0 ||
                      (territoryData &&
                        territoryData.role &&
                        territoryData.role.toLowerCase() !== 'corp' &&
                        territoryData.role.toLowerCase() !== 'market' &&
                        territoryData?.role.toLowerCase() !== 'ma')
                    }
                  >
                    <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                      {t('allRegions')}
                    </Select.Option>
                    {orgDropdownList.region.map(item => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item
                  name="site"
                  // label="Site"
                  rules={[{ required: true, message: t('pleaseSelectSite') }]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    maxTagTextLength={9}
                    onChange={value => handleSelectChange(value, 'site')}
                    placeholder={t('site')}
                    disabled={
                      orgSelectedList.region.length === 0 
                      //COT-8439: SIA - Enable site select when user role is Site
                      // ||(territoryData && territoryData.role && territoryData.role.toLowerCase() === 'site')
                    }
                  >
                    <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                      {t("allSites")}
                    </Select.Option>
                    {orgDropdownList.site.map(item => {
                      const siteNbr = item.site_nbr;
                      const siteNbrName = item.site_desc;
                      return (
                        <Select.Option key={siteNbr} value={siteNbr}>
                          {`${siteNbr} - ${siteNbrName}`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item
                  name="accountType"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    maxTagTextLength={9}
                    onChange={value => handleSelectChange(value, 'accountType')}
                    placeholder={t('dashboard_accountType')}
                    style={{ width: 120 }}
                  >
                    <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                      {t('all_account_types')}
                    </Select.Option>
                    {ACCOUNT_TYPES.map(item => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item
                  name="territory"
                  // label="Territory"
                  rules={[
                    {
                      required: territoryData && territoryData.role.toLowerCase() === 'ma',
                      message: ERROR_SELECT_TERRITORY,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    maxTagTextLength={10}
                    onChange={(value, option) => handleSelectChange(value, 'territory')}
                    placeholder={t("territory")}
                    disabled={orgSelectedList.site.length === 0 || territoryList.length === 0}
                  >
                    {territoryList ? (
                      <>
                        <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                          {t('allTerritories')}
                        </Select.Option>
                        {territoryList.map(item => {
                          return (
                            <Select.Option key={item.terrId} value={item.terrName}>
                              {item.terrName}
                            </Select.Option>
                          );
                        })}
                      </>
                    ) : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item name="nationalId">
                  <Select
                    showSearch
                    allowClear
                    onChange={value => getConceptIdsList(value)}
                    onClear={() => {
                      resetConceptIdsData();
                    }}
                    placeholder={t('natlId')}
                    disabled={isCanadaCorpUser}
                  >
                    {nationalData &&
                      nationalData.map(item => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col><Col flex="1 1 0">
                <Form.Item name="conceptId">
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    maxTagTextLength={6}
                    onChange={(value, option) => handleSelectChange(value, 'conceptId')}
                    placeholder={t('conceptId')}
                    disabled={isCanadaCorpUser}
                  >
                    {conceptIdData && (
                      <Select.Option key={SELECT_ALL_OPTION} value={SELECT_ALL_OPTION}>
                         {t('allConceptIds')}
                      </Select.Option>
                    )}
                    {conceptIdData &&
                      conceptIdData.map(item => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="1 1 0">
                <Form.Item
                  name="deliveryDate"
                  // label="Delivery Date"
                  rules={[{ required: true, message: 'Please select Delivery Date!' }]}
                >
                  <DatePicker disabledDate={disabledDeliveryDate} placeholder="Delivery Date"  style={{width :130}}/>
                </Form.Item>
              </Col>
            </div>
            <div className="sia-filter-footer">
              <Form.Item style={{ textAlign: 'end' }}>
                {/* <Button htmlType="button" onClick={onReset} className="default-btn sia-reset-but" style={{marginRight:'10px' }}>
              Reset
            </Button> */}
                <Button htmlType="submit" type="link" className="primary-btn sia-search-but"  onClick={isClosePopFun}>
                {t('search')}
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Card>
  );
};

ReportsFilter.propTypes = {};
ReportsFilter.defaultProps = {};
