import { App } from '../features/home';
import { PageNotFound } from '../features/common';
import homeRoute from '../features/home/route';
import commonRoute from '../features/common/route';
import _ from 'lodash';
import dashboardRoute from '../features/dashboard/route';
import orderTrailRoute from '../features/order-trail/route';
import reportsRoute from '../features/reports/route';
import manageSubsRoute from '../features/manage-subs/route';
import reportsNewRoute from '../features/reports-new/route';
import ordersRoute from '../features/orders/route';
import futureOrdersRoute from '../features/future-orders/route';
import scInActionRoute from '../features/sc-in-action/route';
import dashboardNewRoute from '../features/dashboard-new/route';
import outOfStockRoute from '../features/out-of-stock/route';
import oosAccountRoute from '../features/oos-account/route';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
const childRoutes = [
  homeRoute,
  // commonRoute,//COT-9353: SIA+ Screen refresh issue. remove duplicate childRoutes
  // dashboardRoute,
  // orderTrailRoute,
  // reportsRoute,
  // manageSubsRoute,
  // reportsNewRoute,
  // ordersRoute,
  // futureOrdersRoute,
  // scInActionRoute,
  // dashboardNewRoute,
  // outOfStockRoute,
  // oosAccountRoute,
];

const routes = [{
  path: '/',
  component: App,
  childRoutes: [
    ...childRoutes,
    { path: '*', name: 'Page not found', component: PageNotFound },
  ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
}];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child => child.isIndex));
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
