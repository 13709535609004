export const ORDER_HEALTH_CHECK_COLUMNS = {
  open: 'Open Orders',
  lockedOrErrored: 'Locked/Errored',
  cancelled: 'Cancelled Orders',
  creditHold: 'Credit Hold',
  merchHold: 'Merch Hold',
  pendingApproval: 'Pending Approval',
  routing: 'Routing',
  labelled: 'Labelled',
  shippedOrInvoiced: 'Shipped/Invoiced',
};

export const ORDER_BY_TYPE_COLUMNS = {
  regular: 'Regular Order',
  willCall: 'Will Call Orders',
  hotShot: 'Hot Shot',
  unconfirmed: 'Unconfirmed Order',
  dropShip: 'Drop Ship',
  others: 'Others',
};

export const EXPECTED_SALES_COLUMNS_ONE = {
  totalSales: 'Total Sales',
  totalCases: 'Total Cases Ordered',
  totalAllocatedCases: 'Total Cases Allocated',
};

export const EXPECTED_SALES_COLUMNS_TWO = {
  totalOrders: 'Total Orders',
  totalEaches: 'Total Eaches Ordered',
  totalAllocatedEaches: 'Total Eaches Allocated',
};

export const EXPECTED_SALES_COLUMNS_THREE = {
  totalLines: 'Total Lines',
  totalExceptions: 'Total Lines w/ Exceptions',
  totalPickups: 'Total Pickups Requests',
};

export const EXPECTED_SALES_REPORT_COLUMNS = {
  totalSales: 'Total Sales',
  totalGPPercentage: 'Total GP Percentage',
  totalOrders: 'Total Orders',
  totalLines: 'Total Lines',
  totalExceptions: 'Total Lines w/ Exceptions',
  totalPieces: 'Total Pieces',
  totalConfPieces: 'Total Conf Pieces',
  avgPricePerPiece: 'Avg Price Per Piece',
  totalPickPieces: 'Total Pick Pieces',
  perfectOrderRate: 'Perfect Order Rate',
};
export const EXCEPTIONS_DETAILS_COLUMNS = {
  exactExceptions: 'EXPD Details',
  totalExceptions: 'Errored Lines',
  subsExceptions: 'Subs',
};
