import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_BEGIN,
  MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_SUCCESS,
  MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_FAILURE,
  MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_DISMISS_ERROR,
} from './constants';
import { BOS_TERRITORY_DATA_KEY } from '../../../common/constants';

export function fetchBosTerritoryData(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
        let languageLocaleKey  = getState().dashboard?.territoryData?.languageLocaleKey;
        let res = {data:{}}
        //all users as Corp users
        if(args.isSpecial) {
          res.data.role = 'Corp'
          res.data.conceptIds = null
          res.data.market = null
          res.data.nationalIds = null
          res.data.region = null
          res.data.sites = null
          res.data.territories = null
          res.data.accessible = true
          res.data.languageLocaleKey = languageLocaleKey
        }
        const data = {
          bosTerritoryData: res.data,
        };
        dispatch({
          type: MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_SUCCESS,
          data,
        });
        resolve(res.data);
    });

    return promise;
  };
}

export function dismissFetchBosTerritoryDataError() {
  return {
    type: MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_DISMISS_ERROR,
  };
}

export function useFetchBosTerritoryData() {
  const dispatch = useDispatch();

  const {bosTerritoryData, fetchBosTerritoryDataPending, fetchBosTerritoryDataError } = useSelector(
    state => ({
      bosTerritoryData: state.manageSubs.bosTerritoryData,
      fetchBosTerritoryDataPending: state.manageSubs.fetchBosTerritoryDataPending,
      fetchBosTerritoryDataError: state.manageSubs.fetchBosTerritoryDataError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchBosTerritoryData(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchBosTerritoryDataError());
  }, [dispatch]);

  return {
    bosTerritoryData,
    fetchBosTerritoryData: boundAction,
    fetchBosTerritoryDataPending,
    fetchBosTerritoryDataError,
    dismissFetchBosTerritoryDataError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchBosTerritoryDataPending: true,
        fetchBosTerritoryDataError: null,
      };

    case MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_SUCCESS:
      // The request is success
      sessionStorage.setItem(BOS_TERRITORY_DATA_KEY,JSON.stringify(action.data));
      return {
        ...state,
        bosTerritoryData: action.data,
        fetchBosTerritoryDataPending: false,
        fetchBosTerritoryDataError: null,
      };

    case MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchBosTerritoryDataPending: false,
        fetchBosTerritoryDataError: action.data.error,
      };

    case MANAGE_SUBS_FETCH_BOS_TERRITORY_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchBosTerritoryDataError: null,
      };

    default:
      return state;
  }
}
