// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { OrderTrail } from './';

export default {
  path: 'order-trail', //COT-9353: SIA+ Screen refresh issue. handle incorrect path
  component: OrderTrail,
  childRoutes: [
  ],
};
